import axios from 'axios';

const publicFetch = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'https://dmhhh49ag5.execute-api.us-east-1.amazonaws.com/api/', //DEV
  baseURL: 'https://bjvzypgbog.execute-api.us-east-1.amazonaws.com/api/', //PROD  
  withCredentials: true
});

const getErrorMessage = (error) => {
    let message = '';
    const { response } = error;
    if (typeof response == 'undefined') {
      message = 'Server did not respond';
    } else if (typeof response.data == 'undefined') {
      message = 'Server did not respond';
    } else {
        const data = response.data;
        if (!data.message) {
            message = 'Server did not return message';

        } else {
            message = data.message;
        }
    }
    return message;
}

export { publicFetch,getErrorMessage };
